<template>
    <div class="home p-4">
        <div>
            <h3>Suvidi Rice</h3>
            <p>From the rice paddies of Kerala comes a story that brims with hope; a group of farmers committed to growing nutritious food the natural way, and their determination...</p>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'Home'
};

</script>
